export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['🐹Ого-го!🐹', '🐹Да ладно?!!🐹', '🐹Супер!🐹', '🐹Ты просто космос!🐹', '🐹Вот это да!🐹', '🐹Замечательно!🐹', '🐹Верно!🐹', '🐹Браво!!!🐹', '🐹Мои поздравления!🐹', '🐹Хорошая работа!🐹', '🐹Великолепно!🐹', '🐹Отлично!🐹', '🐹Красота!🐹', '🐹Удивительно!🐹', '🐹Наконец-то!🐹', '🐹Так держать!🐹', '🐹Прекрасно!🐹', '🐹Фантастика!🐹', '🐹Невероятно!🐹', '🐹Восхитительно!🐹', '🐹Терпение и труд всё перетрут!🐹', '🐹Необыкновенно!🐹', '🐹Сенсация!🐹', '🐹Первоклассная работа!🐹', '🐹Гениально!🐹', '🐹Вот это класс!🐹', '🐹Молодчинка!🐹', '🐹Виртуоз!🐹', '🐹Ты просто гений!🐹', '🐹Потрясающе!🐹', '🐹Гордость ты наша!🐹', '🐹Поразительно!🐹', '🐹Это-высший пилотаж!🐹', '🐹Расскажи,как тебе это удалось!?🐹', '🐹Вот это финал!🐹', '🐹Вот это да!🐹', '🐹Как так-то?!!🐹']
export const GAME_COPIED_MESSAGE = 'Скопировано в буфер'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Не хватает букв'
export const WORD_NOT_FOUND_MESSAGE = 'Нет такого слова! Может и есть, но тут нет.'
export const HARD_MODE_ALERT_MESSAGE =
  'Сложный режим можно включить только перед стартом игры!'
export const HARD_MODE_DESCRIPTION =
  'Любые обнаруженные подсказки должны использоваться в последующих догадках.'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Для улучшения цветового зрения.'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Это было слово ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Нужно использовать ${guess} в позиции ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Догадка должна содержать ${letter}`
export const ENTER_TEXT = 'Ввод'
export const DELETE_TEXT = 'Удалить'
export const STATISTICS_TITLE = 'Статистика'
export const GUESS_DISTRIBUTION_TEXT = 'Угадывание'
export const NEW_WORD_TEXT = 'Новое слово через'
export const SHARE_TEXT = 'Поделится'
export const TOTAL_TRIES_TEXT = 'Всего попыток'
export const SUCCESS_RATE_TEXT = 'Процент успеха'
export const CURRENT_STREAK_TEXT = 'Текущий стрик'
export const BEST_STREAK_TEXT = 'Лучший стрик'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "Вы используете встроенный браузер, и у вас могут возникнуть проблемы с отправкой или сохранением результатов. Рекомендуется использовать браузер по умолчанию на вашем устройстве."
