import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Как играть" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Угадайте загаданное слово с шести попыток. После каждой попытки цвет букв будет меняться, чтобы показать какие буквы есть в загаданном слове!
      </p>
 <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
       ____________________________________________
      </p>
      <p className="mt-6 text-sm italic text-gray-500 dark:text-red-300">
        Новое слово каждый день в 03:00 и в 15:00!!!
      </p>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="В"
          status="correct"
        />
        <Cell value="О" isCompleted={true} />
        <Cell value="М" isCompleted={true} />
        <Cell value="Б" isCompleted={true} />
        <Cell value="А" isCompleted={true} />
        <Cell value="T" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Зеленая ячейка означает, что в отгадываемом слове буква в этой ячейке есть и находится именно на этом месте.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="К" isCompleted={true} />
        <Cell value="Р" isCompleted={true} />
        <Cell value="У" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="Ж"
          status="present"
        />
        <Cell value="К" isCompleted={true} />
        <Cell value="А" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
         Желтая ячейка - буква есть в отгадываемом слове, но не на этом месте!
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="Ж" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="Р" status="absent" />
        <Cell value="Е" isCompleted={true} />
        <Cell value="Б" isCompleted={true} />
        <Cell value="И" isCompleted={true} />
        <Cell value="Й" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Ячейка серая - такой буквы вообще нет в этом слове. Крутите барабан!
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        Буквы в слове могут повторятся, при угадывании будет показано одно случайное вхождение. Буква Ё равна Е.
      </p>
      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
       ____________________________________________
      </p>
      <p className="mt-6 text-sm italic text-gray-500 dark:text-red-300">
        В настройках есть сложный режим!!!
      </p>
    </BaseModal>
  )
}
