export const WORDS = [
'диссидент',
'классика',
'субсидия',
'климат',
'нытье',
'фолликул',
'щебет',
'рассрочка',
'сударь',
'плагиат',
'бородавка',
'нафталин',
'визажист',
'каракурт',
'ордер',
'трагедия',
'потоп',
'наследие',
'фетиш',
'артерия',
'кретин',
'изучение',
'люлька',
'скрипка',
'талия',
'атрофия',
'бармен',
'противник',
'сумрак',
'венец',
'барсук',
'командир',
'оппонент',
'светлость',
'бронхит',
'мушкетер',
'пришелец',
'толщина',
'зажигалка',
'искушение',
'омлет',
'трущоба',
'ревматизм',
'голубятня',
'знать',
'редька',
'берет',
'отель',
'капля',
'хвальба',
'ластик',
'волна',
'стагнация',
'слепота',
'фобия',
'алкаш',
'циник',
'перегиб',
'пипетка',
'уголь',
'примета',
'абсурд',
'матрос',
'балагур',
'ритуал',
'район',
'сутенер',
'раболепие',
'нефтяник',
'юноша',
'вагонетка',
'гипотеза',
'маркиз',
'дьявол',
'гарнизон',
'россыпь',
'шахта',
'самоотвод',
'египтолог',
'шпаргалка',
'единение',
'кочевник',
'зелень',
'черчение',
'профессия',
'эскалация',
'вакцина',
'шпагат',
'инфаркт',
'микроб',
'травма',
'руина',
'унылость',
'лишай',
'молитва',
'аннексия',
'раскаяние',
'фильм',
'статут',
'комод',
'рюмка',
'глумление',
'лицензия',
'кукла',
'скорпион',
'редактор',
'цирроз',
'держатель',
'балык',
'хореограф',
'фауна',
'чемпион',
'виноград',
'отсрочка',
'персонаж',
'тщетность',
'десерт',
'серенада',
'оффис',
'писарь',
'сюрприз',
'брикет',
'тинейджер',
'бунтарь',
'номинация',
'жадность',
'пластина',
'смерч',
'хранение',
'оборот',
'приемная',
'реакция',
'консенсус',
'карманник',
'апрель',
'карантин',
'варвар',
'кожух',
'предлог',
'барышня',
'педагог',
'лозунг',
'звездопад',
'гений',
'фейерверк',
'этикет',
'внешность',
'порция',
'морковь',
'экземпляр',
'сорока',
'чукча',
'капюшон',
'убеждение',
'шедевр',
'кляуза',
'кощунство',
'энергия',
'кадастр',
'особа',
'скепсис',
'пекло',
'пропуск',
'туннель',
'божество',
'юрист',
'яблоня',
'сатира',
'тампонада',
'мизантроп',
'смрад',
'нудист',
'склероз',
'сгусток',
'плакса',
'шпана',
'заказник',
'привычка',
'запах',
'вафля',
'таймер',
'монумент',
'оружие',
'импульс',
'арбалет',
'фаворит',
'казначей',
'свинарник',
'перловка',
'бастурма',
'кирпич',
'йогурт',
'прибавка',
'дизель',
'тюльпан',
'местность',
'живность',
'кинолог',
'красильня',
'нокаут',
'марганец',
'подвиг',
'гимназия',
'скальпель',
'аттестат',
'оттепель',
'рабыня',
'прогулка',
'попутчик',
'двуличие',
'хурма',
'древесина',
'тубус',
'спутница',
'олимпиада',
'деревня',
'судья',
'афоризм',
'медиум',
'рубероид',
'контакт',
'звезда',
'перипетия',
'подвал',
'декольте',
'практикум',
'тоник',
'грудничок',
'нашествие',
'самбука',
'нарцисс',
'латекс',
'взрыв',
'географ',
'дельфин',
'ропот',
'резидент',
'униформа',
'лингвист',
'судак',
'акционер',
'бестолочь',
'генератор',
'рекорд',
'лимон',
'литургия',
'падчерица',
'медитация',
'алчность',
'транспорт',
'дрель',
'апноэ',
'теплица',
'гинеколог',
'активист',
'портвейн',
'творог',
'педикюр',
'грудь',
'герань',
'экватор',
'ламантин',
'канделябр',
'клумба',
'дуплекс',
'ястреб',
'слово',
'побег',
'геноцид',
'инерция',
'парашют',
'вотчина',
'пальба',
'пещера',
'контора',
'титул',
'князь',
'сплав',
'аналитика',
'аккордеон',
'детонация',
'вьюга',
'реформа',
'радикал',
'отродье',
'корзина',
'минтай',
'перекись',
'галерея',
'тирада',
'небоскреб',
'курьер',
'пешеход',
'крепление',
'фейхоа',
'амулет',
'мания',
'заявитель',
'бешенство',
'экипаж',
'барыш',
'передача',
'ведомство',
'сурок',
'пропажа',
'рожон',
'ежевика',
'календарь',
'центнер',
'ревизия',
'амуниция',
'пропойца',
'диагональ',
'откос',
'прицеп',
'коряга',
'решето',
'убожество',
'каламбур',
'козерог',
'кровь',
'квартет',
'агония',
'смычок',
'верзила',
'трусы',
'кукуруза',
'масса',
'геометрия',
'рычаг',
'пандемия',
'ведро',
'инфекция',
'костюм',
'сквер',
'лысина',
'сафари',
'кальций',
'бюрократ',
'ресторан',
'самбо',
'рефери',
'исцеление',
'альбатрос',
'булыжник',
'газель',
'валерьяна',
'кавалерия',
'чистка',
'снабженец',
'трутень',
'ценность',
'хоровод',
'конфета',
'предтеча',
'ювелир',
'депрессия',
'смеситель',
'рулет',
'интерьер',
'пустошь',
'сатурация',
'методика',
'святыня',
'гильотина',
'химикат',
'алтарь',
'корнишон',
'покрытие',
'рикошет',
'охранник',
'торпеда',
'серпантин',
'окисление',
'крокодил',
'дьякон',
'кузнец',
'выхлоп',
'кондитер',
'микрофон',
'скандал',
'стереотип',
'моллюск',
'коптильня',
'стержень',
'страж',
'фокус',
'латиница',
'кухня',
'нерест',
'оцепление',
'щелчок',
'фортуна',
'показ',
'паралич',
'иерарх',
'аллегория',
'нейлон',
'прореха',
'виновник',
'симпатия',
'чебурек',
'снаряд',
'пневмония',
'мегаполис',
'частушка',
'стрелец',
'разница',
'взвизг',
'мерзость',
'уголовник',
'заика',
'манифест',
'пробоина',
'стрижка',
'клиент',
'дупло',
'проповедь',
'папайя',
'беготня',
'ананас',
'жилище',
'пьеса',
'шеренга',
'жилец',
'пиршество',
'лейтенант',
'навигация',
'слуга',
'разъем',
'повидло',
'арбитр',
'лизоблюд',
'инстинкт',
'фольга',
'пламя',
'лихорадка',
'структура',
'трафарет',
'ножницы',
'винегрет',
'коллега',
'шпионаж',
'психика',
'подделка',
'постамент',
'альвеола',
'извилина',
'взлет',
'соболь',
'чудовище',
'жизнь',
'лекарь',
'баталия',
'норматив',
'филолог',
'колледж',
'астероид',
'стеклодув',
'яблоко',
'костюмер',
'снасть',
'банкрот',
'фурункул',
'учтивость',
'коллектив',
'сквозняк',
'салют',
'кофеин',
'молекула',
'экономика',
'герцог',
'стажер',
'шиворот',
'исчадие',
'астронавт',
'колокол',
'альтруизм',
'печень',
'достаток',
'община',
'обитатель',
'кандидат',
'богатство',
'дробовик',
'селектор',
'гроздь',
'провал',
'тренинг',
'павильон',
'приемник',
'сталь',
'маневр',
'чечевица',
'шампур',
'акушер',
'папиллома',
'пауза',
'население',
'гематома',
'факториал',
'шелушение',
'экзекуция',
'баловень',
'куница',
'спаржа',
'саквояж',
'погреб',
'зубило',
'коагулянт',
'променад',
'пекарня',
'зодиак',
]
